import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

class InformationsPage extends Component {
  componentDidMount() {
    //
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Bagoly-Lak - Hasznos információk</title>
        </Helmet>
        <div className="main">
          <img className="main__background-full" src="assets/background-full.png" alt="Háttérkép" />
          <img
            className="main__background-minimal"
            src="assets/background-minimal.png"
            alt="Háttérkép"
          />
          <img className="main__logo" src="assets/bagoly-lak-logo.png" alt="Logo" />
          <div className="main__menu main__menu--desktop">
            <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
            <div className="main__menu__menus">
              <Link to="/">Bemutató</Link>
              <Link to="/matraszentimre">Mátraszentimre</Link>
              <Link to="/galeria">Galéria</Link>
              <Link to="/hasznos-informaciok">Hasznos információk</Link>
              <Link to="/szabadidos-programok">Szabadidős programok</Link>
              <a
                href="https://nethotelbooking.net/hotels/bagoly-lak/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Foglalás
              </a>
              <Link to="/elerhetoseg">Elérhetőség</Link>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="main__menu main__menu--mobile">
                  <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
                  <div className="main__menu__menus">
                    <Link to="/">Bemutató</Link>
                    <Link to="/matraszentimre">Mátraszentimre</Link>
                    <Link to="/galeria">Galéria</Link>
                    <Link to="/hasznos-informaciok">Hasznos információk</Link>
                    <Link to="/szabadidos-programok">Szabadidős programok</Link>
                    <a
                      href="https://nethotelbooking.net/hotels/bagoly-lak/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Foglalás
                    </a>
                    <Link to="/elerhetoseg">Elérhetőség</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="informations">
                    <h1>Hasznos információk</h1>

                    <p className="bold">Jöjjön és pihenjen meg Bagolyirtás legrégebbi házában!</p>

                    <p>
                      Az alábbiakban pár hasznos információt osztunk meg Önnel a Bagoly lakkal
                      kapcsolatban.
                    </p>

                    <p>
                      <span className="bold">Cím, megközelítés:</span> 3235
                      Mátraszentimre-Bagolyirtás, Jókai u. 19.
                    </p>

                    <p>
                      M3 Hatvani lehajtójánál térjen le, majd a 21-es úton haladjon Pásztóig,
                      Pásztón Galyatető felé forduljon. Erről az útról tud majd lehajtani a szállás
                      felé, a Mátraszentimre-Bagolyirtás táblánál. Kövesse a „Mátraszíve” panzió
                      táblát, ez a közvetlen szomszédunk.
                    </p>

                    <p>
                      <span className="bold">Internet, wifi kód:</span> e-mailben küldjük
                    </p>

                    <p>
                      <span className="bold">Víz:</span> a vizet a fürdőszobában, a mosdó melletti
                      fekete csappal tudja megnyitni. Távozáskor kérjük zárja el!
                    </p>

                    <p>
                      <span className="bold">Ágynemű, törölköző:</span> Ágyneműt (ágyhuzat,
                      párnahuzat, paplan, párna, lepedő, pléd) és törölközőt a nappali szekrényben
                      talál. Távozáskor kérjük, a fürdőben található szennyeskosárba dobja be a
                      használtakat!
                    </p>

                    <p>
                      <span className="bold">Áramellátás, fűtés:</span> A legtöbb konnektoron
                      (D-link) van egy gomb, amivel be tudja kapcsolni az elektromos fűtőtesteket és
                      az infrapanelt ill. a fürdőben található törölköző szárítót. Kérjük, ne
                      állítson minden fűtőtestet maximum értékre, mert az épület áramellátottsága
                      korlátozott! (25A)
                    </p>

                    <p>
                      <span className="bold">TV:</span> az LG távirányítóval kapcsolja a TV-t HDMI3
                      bemenetre, utána a másik távirányítóval tudja váltogatni a csatornákat. A
                      retro játékkonzol az AV bemeneten elérhető.
                    </p>

                    <p>
                      Porszívó (konyhában a mikrosütő alatti szekrényben), hajszárító (fürdőszoba
                      szekrényben), vasalódeszka (nappali szekrény mellett) és vasaló (tükör alatti
                      polcrendszerben), valamint kv főző is található az ingatlanban. A KV főző
                      őrölt kv-val működik. (kapszulát, kv filtert, kv párnát nem lehet bele tenni).
                      Ha van kedvenc kávéja, kérjük hozza magával, hogy még kellemesebbé tudja
                      varázsolni pihenését! A konyhaszekrényben só, cukor, teafilter, 3in1 kv
                      található, fogyassza bátran!
                    </p>

                    <p>
                      <span className="bold">Kültéri asztalok, székek:</span> Tavasztól őszig egy
                      asztal és 2 pad található a ház tornácán, ezt tudja kitenni a nyársaló
                      melletti kövezett területre. A tornácon lévő pad alatt található a napernyő
                      tartó, az étkezőben pedig maga a napernyő, illetve a napágyak is.
                    </p>

                    <p>
                      <span className="bold">Nyársalás, grillezés:</span> A nyársbotokat az udvarra
                      néző vakablakban találja, grill gyújtóval együtt. A tárcsa a nyársaló hely
                      tetején található, a bogrács az étkező szekrényben. Kérjük, használat után
                      tisztán tegye vissza az eszközöket a helyükre!
                    </p>

                    <p>
                      <span className="bold">Elsősegély készlet:</span> A konyhaszekrényben
                      található, a mikrósütő melletti szekrényben egy piros kistáskában.
                    </p>

                    <p>
                      <span className="bold">Szemét:</span> A szemetet kérjük, a konyhában található
                      szemetesbe gyűjtse és távozáskor a Mátraszentimre-Bagolyirtás elágazásnál
                      található konténerbe dobja ki.
                    </p>

                    <p>
                      <span className="bold">Étterem, bolt:</span> A legközelebbi ABC
                      Mátraszentimrén található, ugyanitt találja a BéTerv éttermet is. A
                      továbbiakban szeretettel ajánlunk néhány látnivalót a környéken.
                    </p>

                    <div className="bigFishContainer">
                      <Link
                        to="https://www.paymentgateway.hu/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="bigFish"
                          src="assets/pmgw-logo-narrow-white.png"
                          alt="PaymentGatewayLogo"
                          width={200}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main__separator" />
        </div>
      </>
    );
  }
}

export default InformationsPage;
